import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import ArticlePreview from "../../templates/articlePreview";
import {isMobile} from "react-device-detect";
import {adDisplayDelay, delayMultiplicationFactor} from "../../utils/articleUtil";
import cx from "classnames";

class PopularPosts extends React.Component {

    constructor(props) {
        super(props);
        this.className = cx(
          "popular-posts article-popular hidden w-[405px] min-h-[423px] min-w-[405px] lg:flex flex-col justify-between p-4 pb-8 bg-gray-100 flex-1 ml-2 relative",
          props.className
        )
    }

    /*componentDidMount() {
        setTimeout(() => {
            try {
                googletag.cmd.push(function () {
                    googletag.display('div-at_rightcol_desktop');
                });
            } catch (e) {
                console.log(e);
            }
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
    }*/

    renderItems() {
        return <StaticQuery
            query={graphql`
            query PopularPostsQuery {
              video: allDatoCmsArticle(
                    filter: {category: {elemMatch: {originalId: {in: ["39161838"]}}}, website: {elemMatch: {name: {eq: "at"}}}}
                    limit: 1
                    skip: 30
                ) {
                    nodes {
                        ...ArticleFieldsPluginWithPortraitImage
                    }
                }
                nonVideo: allDatoCmsArticle(
                    filter: {category: {elemMatch: {originalId: {nin: ["39161838"]}}}, website: {elemMatch: {name: {eq: "at"}}}}
                    limit: 3
                    skip: 30
                ) {
                    nodes {
                        ...ArticleFieldsPluginWithPortraitImage
                    }
                }
            }
        `}
            render={data =>
                data.nonVideo.nodes.concat(data.video.nodes).slice(0, 3)
                    .map((article, index) => {
                    return <ArticlePreview
                      key={`top-right-${index}`} data={article}
                      className="flex w-full"
                      imageClass="mb-0 mr-2 float-left clear-both"
                      gatsbyImageClass="w-[70px] h-full"
                      headingClass="font-sans font-semibold leading-snug text-lg mb-0 line-clamp l-c-3"
                      noFlag={true}
                      noBody={true}
                      noAuthor={true}
                      wrappedImg={false}
                      dateAsFlag={true}
                      titleAuthorDisplayClass={"flex-col-reverse"}
                      publishedAtFormat={"mmmm dd, yyyy"}/>
                })
            }
        />
    }

    render() {
        return !isMobile && <div className={this.className} data-datocms-noindex>
            {this.renderItems()}
        </div>
    }
}

export default PopularPosts;
